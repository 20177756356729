import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

interface MailChimpResponse {
    result: string;
    msg: string;
}
@Component({
    selector: 'app-coming-soon-page',
    templateUrl: './coming-soon-page.component.html',
    styleUrls: ['./coming-soon-page.component.scss']
})
export class ComingSoonPageComponent implements OnInit {

    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    myInterval: any;
    formData;
    emailId;
    mailChimpEndpoint = 'https://zartugames.us1.list-manage.com/subscribe/post-json?u=b2a62f3dd57319d71b682033f&amp;id=1604f29fcf&';
    submitted = false;

    constructor(private http: HttpClient) { }

    ngOnInit() {
        this.myInterval = setInterval(() => {
            this.commingSoonTime();
        }, 0);
        this.formData = new UntypedFormGroup({
            emailId: new UntypedFormControl('', [
                Validators.required,
                Validators.email
            ])
        });
    }

    // onClickSubmit(data) {
    //     this.emailId = data.emailId;
    // }

    submit() {
        if (this.formData.status === 'VALID') {

            const params = new HttpParams()
                .set('EMAIL', this.formData.value.emailId)
                .set('b_b2a62f3dd57319d71b682033f_1604f29fcf', ''); // hidden input name

            const mailChimpUrl = this.mailChimpEndpoint + params.toString();

            // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
            this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
                if (response.result && response.result !== 'error') {
                    this.submitted = true;
                    this.emailId = this.formData.value.emailId;
                } else {
                    console.log(response.msg);
                }
            }, error => {
                console.error(error);
            });
        }
    }

    commingSoonTime = () => {
        const endTimeParse = (Date.parse('May 1, 2024 12:00:00 UTC')) / 1000;
        const now = new Date();
        const nowParse = (Date.parse(now.toString()) / 1000);
        const timeLeft = endTimeParse - nowParse;
        const days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < 10) { hours = 0 + hours; }
        if (minutes < 10) { minutes = 0 + minutes; }
        if (seconds < 10) { seconds = 0 + seconds; }
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }

}
