<div class="container">
    <div class="section-title">
        <span class="sub-title">Shop</span>
        <h2>Our Gaming Products</h2>
    </div>

    <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-products-box">
                <div class="products-image">
                    <a routerLink="/single-products" class="d-block"><img src="assets/img/products-img1.jpg" alt="image"></a>
                    <a routerLink="/" class="add-to-cart-btn">Add To Cart</a>
                </div>

                <div class="products-content">
                    <h3><a routerLink="/single-products">Gaming Headphone</a></h3>
                    <span class="price">$99.00</span>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-products-box">
                <div class="products-image">
                    <a routerLink="/single-products" class="d-block"><img src="assets/img/products-img2.jpg" alt="image"></a>
                    <a routerLink="/" class="add-to-cart-btn">Add To Cart</a>
                </div>

                <div class="products-content">
                    <h3><a routerLink="/single-products">Gaming Chair</a></h3>
                    <span class="price">$199.00</span>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-products-box">
                <div class="products-image">
                    <a routerLink="/single-products" class="d-block"><img src="assets/img/products-img3.jpg" alt="image"></a>
                    <a routerLink="/" class="add-to-cart-btn">Add To Cart</a>
                </div>

                <div class="products-content">
                    <h3><a routerLink="/single-products">Gaming Mouse</a></h3>
                    <span class="price">$69.00</span>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 offset-lg-0 offset-md-4">
            <div class="single-products-box">
                <div class="products-image">
                    <a routerLink="/single-products" class="d-block"><img src="assets/img/products-img4.jpg" alt="image"></a>
                    <a routerLink="/" class="add-to-cart-btn">Add To Cart</a>
                </div>

                <div class="products-content">
                    <h3><a routerLink="/single-products">Gaming Pad</a></h3>
                    <span class="price">$299.00</span>
                </div>
            </div>
        </div>
    </div>
</div>