import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wop-home',
  templateUrl: './wop-home.component.html',
  styleUrls: ['./wop-home.component.scss']
})
export class WopHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
