<app-navbar-style-one></app-navbar-style-one>

<app-main-banner></app-main-banner>

<section class="matches-area ptb-100">
    <app-matches-style-one></app-matches-style-one>

    <div class="shape1"><img src="assets/img/shape1.png" class="wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape2.png" class="wow fadeInRight" data-wow-delay="00ms" data-wow-duration="1500ms" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" class="wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" class="wow fadeInRight" data-wow-delay="00ms" data-wow-duration="1500ms" alt="image"></div>
</section>

<section class="blog-area pb-100">
    <app-news></app-news>
</section>

<app-awards></app-awards>

<section class="team-area ptb-100">
    <app-team></app-team>
</section>

<section class="products-area pb-70">
    <app-shop-style-one></app-shop-style-one>
</section>

<div class="partner-area pt-100 pb-70 pt-0-res">
    <app-partner></app-partner>

    <div class="partner-shape1"><img src="assets/img/partner-shape.png" alt="image"></div>
</div>

<section class="subscribe-area">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<section class="social-area pt-100 pb-70">
    <app-social></app-social>
</section>

<footer class="footer-area">
    <app-footer-style-one></app-footer-style-one>
</footer>