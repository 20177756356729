<app-navbar-style-wop></app-navbar-style-wop>

<app-wop-banner></app-wop-banner>


<div class="footer-area-bg-image">

    <section class="subscribe-area">
        <app-subscribe-style-one></app-subscribe-style-one>
    </section>

    <section class="social-area pt-100 pb-70">
        <app-social></app-social>
    </section>
    
    <footer class="footer-area">
        <app-footer-style-one></app-footer-style-one>
    </footer>

</div>
