import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stream-schedule-page',
  templateUrl: './stream-schedule-page.component.html',
  styleUrls: ['./stream-schedule-page.component.scss']
})
export class StreamSchedulePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
