<footer class="footer-style-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>
                    <p>Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>

                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Case Studies</a></li>
                        <li><a routerLink="/">Our Blog</a></li>
                        <li><a routerLink="/">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-3">
                    <h3>Resources</h3>

                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Case Studies</a></li>
                        <li><a routerLink="/">Our Blog</a></li>
                        <li><a routerLink="/">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-3">
                    <h3>Address</h3>

                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>2750 Quadra Street Victoria Road, New York, United States</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+44587154756">+1 (123) 456 7890</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:hello@zinble.com">hello@zinble.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+55 785 4578964</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p><i class='bx bx-copyright'></i>2021 <a routerLink="/">Zinble</a> games Pty Ltd.</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-map"><img src="assets/img/footer-map.png" alt="image"></div>
</footer>