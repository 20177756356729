<div class="container">
    <div class="subscribe-inner ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
        <span class="sub-title">Get Updates</span>
        <h2>Zinble Newsletter</h2>
        <form class="newsletter-form">
            <label><i class="flaticon-null-1"></i></label>
            <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
            <button type="submit" class="default-btn">Subscribe Now</button>
        </form>
    </div>
</div>