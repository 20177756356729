<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <img src="assets/img/team1.png" alt="image">
            <br>
            <h1 title="FIERCE">FIERCE</h1>
        </div>
    </div>
</section>

<section class="team-details-area ptb-100">
    <div class="container">
        <div class="team-details-desc">
            <span class="sub-title">DOTA 01</span>
            <h3>About the Team</h3>
            <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.</p>

            <ul class="team-meta">
                <li>
                    <div class="icon">
                        <i class="flaticon-medal"></i>
                    </div>
                    <span>01</span>
                    Wins
                </li>

                <li>
                    <div class="icon">
                        <i class="flaticon-fall"></i>
                    </div>
                    <span>01</span>
                    Loses
                </li>

                <li>
                    <div class="icon">
                        <i class="flaticon-game"></i>
                    </div>
                    <span>00</span>
                    Draws
                </li>

                <li>
                    <div class="icon">
                        <i class="flaticon-teamwork"></i>
                    </div>
                    <span>02</span>
                    Plays
                </li>

                <li>
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    <div class="social">
                        <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
                        <a href="#" target="_blank"><i class="bx bxl-twitch"></i></a>
                        <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
                        <a href="#" target="_blank"><i class="bx bxl-youtube"></i></a>
                    </div>
                    Follow
                </li>
            </ul>

            <div class="player-list">
                <h3>Gamestar</h3>
                
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-team-member">
                            <img src="assets/img/team-member1.jpg" alt="image">

                            <div class="content">
                                <h3>Sarah Taylor</h3>
                                <span>Sarah3s</span>
                            </div>

                            <a routerLink="/single-player" class="link-btn"></a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-team-member">
                            <img src="assets/img/team-member2.jpg" alt="image">

                            <div class="content">
                                <h3>Merv Adrian</h3>
                                <span>Zelda3s</span>
                            </div>

                            <a routerLink="/single-player" class="link-btn"></a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-team-member">
                            <img src="assets/img/team-member3.jpg" alt="image">

                            <div class="content">
                                <h3>Carla Gentry</h3>
                                <span>3s.King</span>
                            </div>

                            <a routerLink="/single-player" class="link-btn"></a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-team-member">
                            <img src="assets/img/team-member4.jpg" alt="image">

                            <div class="content">
                                <h3>Liam Oliver</h3>
                                <span>Junior.Zelda</span>
                            </div>

                            <a routerLink="/single-player" class="link-btn"></a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-team-member">
                            <img src="assets/img/team-member5.jpg" alt="image">

                            <div class="content">
                                <h3>Emma Olivia</h3>
                                <span>King.Zelda</span>
                            </div>

                            <a routerLink="/single-player" class="link-btn"></a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-team-member">
                            <img src="assets/img/team-member6.jpg" alt="image">

                            <div class="content">
                                <h3>Elijah William</h3>
                                <span>Zelda</span>
                            </div>

                            <a routerLink="/single-player" class="link-btn"></a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-team-member">
                            <img src="assets/img/team-member8.jpg" alt="image">

                            <div class="content">
                                <h3>Kylo Jennar</h3>
                                <span>Zelda.Kylo</span>
                            </div>

                            <a routerLink="/single-player" class="link-btn"></a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-team-member">
                            <img src="assets/img/team-member7.jpg" alt="image">

                            <div class="content">
                                <h3>Lucas Luiz</h3>
                                <span>luiz.zelda</span>
                            </div>

                            <a routerLink="/single-player" class="link-btn"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="streams-list">
                <h3>STREAMS</h3>

                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-live-stream-item">
                            <img src="assets/img/trending-img1.jpg" alt="image">

                            <div class="content">
                                <h3>Fortnite Light</h3>
                                <ul class="meta">
                                    <li>Youtube</li>
                                    <li>Online</li>
                                </ul>
                            </div>

                            <a href="#" class="video-btn"><i class="flaticon-play-button"></i></a>
                            <a href="#" target="_blank" class="link-btn"></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-live-stream-item">
                            <img src="assets/img/trending-img2.jpg" alt="image">

                            <div class="content">
                                <h3>Apex Legends</h3>
                                <ul class="meta">
                                    <li>Vimeo</li>
                                    <li>Online</li>
                                </ul>
                            </div>

                            <a href="#" class="video-btn"><i class="flaticon-play-button"></i></a>
                            <a href="#" target="_blank" class="link-btn"></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="single-live-stream-item">
                            <img src="assets/img/trending-img3.jpg" alt="image">

                            <div class="content">
                                <h3>Past Cure</h3>
                                <ul class="meta">
                                    <li>Twitch</li>
                                    <li>Offline</li>
                                </ul>
                            </div>

                            <a href="#" class="video-btn"><i class="flaticon-play-button"></i></a>
                            <a href="#" target="_blank" class="link-btn"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="partner-list">
                <app-partner></app-partner>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>