<div class="container">
    <div class="footer-content">
        <div class="logo">
            <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            <ul class="footer-menu">
                <li class="nav-item"><a class="nav-link" routerLink="/">Legal</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/">Terms of Use</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/">Privacy policy</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/">Cockie setting</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/">Support center</a></li>
            </ul>
        </div>
    </div>
</div>

<div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-5">
                <p><i class='bx bx-copyright'></i>2021 <a href="https://zartugames.com">Zartu Games UG </a></p>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-7">
                <div class="lang-switcher">
                    <span>{{ 'footer.lang.selection' | translate }}</span>
                    <mat-form-field>
                        <mat-select placeholder="{{ languages[0].viewValue | translate }}" 
                        (selectionChange)="useLanguage(selectedValue)"
                        [(ngModel)]="selectedValue" 
                        name="language" 
                        panelClass="selectLanguage">
                            <mat-option *ngFor="let language of languages" [value]="language.value">
                                {{ language.viewValue | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>