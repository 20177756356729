import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterStyleOneComponent implements OnInit {

  panelLang = new UntypedFormControl('en');
  selectedValue: string;

  languages = [
    {value: 'en', viewValue: 'footer.lang.en'},
    {value: 'de', viewValue: 'footer.lang.de'}
  ];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  useLanguage(language: string): void {
    this.translate.use(language);
  }
}
