<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Faqs">Faqs</h1>
        </div>
    </div>
</section>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-flag'></i> <span>Getting Started</span></a></li>
                
                <li><a href="#"><i class='bx bxs-badge-dollar'></i> <span>Pricing & Planes</span></a></li>

                <li><a href="#"><i class='bx bx-shopping-bag'></i> <span>Sales Question</span></a></li>

                <li><a href="#"><i class='bx bx-book-open'></i> <span>Usage Guides</span></a></li>

                <li><a href="#"><i class='bx bx-info-circle'></i> <span>General Guide</span></a></li>
            </ul>

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What does a digital marketer do?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Would my business benefit from digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the biggest mistake digital marketers make?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How often should I update my website?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What does a digital marketer do?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Would my business benefit from digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the biggest mistake digital marketers make?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How often should I update my website?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What does a digital marketer do?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Would my business benefit from digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the biggest mistake digital marketers make?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How often should I update my website?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What does a digital marketer do?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Would my business benefit from digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the biggest mistake digital marketers make?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How often should I update my website?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What does a digital marketer do?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Would my business benefit from digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the biggest mistake digital marketers make?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How often should I update my website?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>