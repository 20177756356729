import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-style-wop',
  templateUrl: './navbar-style-wop.component.html',
  styleUrls: ['./navbar-style-wop.component.scss']
})
export class NavbarStyleWopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
