<section class="subscribe-area ptb-100 bg-1b060a">
    <div class="container">
        <div class="section-title mb-0">
            <span class="sub-title">get updates</span>
            <h2>Zinble Newsletter</h2>
            <form class="newsletter-form">
                <label><i class="flaticon-null-1"></i></label>
                <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                <button type="submit" class="default-btn">Subscribe Now</button>
            </form>
        </div>
    </div>
</section>