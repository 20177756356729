<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="main-banner-content">
            <span class="sub-title wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Enjoy The Game</span>
            <div class="logo wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1000ms">
                <img src="assets/img/zelda.png" alt="image">
            </div>
            <h6 class="wow fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">Available Now</h6>
            <div class="btn-box wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1000ms">
                <a routerLink="/" class="default-btn">{{ 'banner.btn.coming.soon' | translate }}</a>
                <a routerLink="/contact" class="optional-btn">Free Trial</a>
            </div>
        </div>

        <div class="banner-video-slides owl-carousel owl-theme">
            <div class="banner-video-box">
                <img src="assets/img/main-banner-video-img1.jpg" alt="image">

                <div class="content">
                    <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                    <span class="title">official Trailer 1</span>
                </div>
            </div>

            <div class="banner-video-box">
                <img src="assets/img/main-banner-video-img2.jpg" alt="image">

                <div class="content">
                    <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                    <span class="title">official Trailer 2</span>
                </div>
            </div>
        </div>
    </div>
</div>