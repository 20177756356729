<div class="container">
    <div class="section-title">
        <span class="sub-title">Games</span>
        <h2>Upcoming Tournaments</h2>
    </div>

    <div class="single-tournaments-box">
        <div class="tournaments-image">
            <div class="image bg1">
                <img src="assets/img/upcoming-tournaments-img1.jpg" alt="image">
            </div>
        </div>

        <div class="tournaments-content">
            <div class="content">
                <h3><a routerLink="/single-tournament">Apex Legends Global Series</a></h3>
                <div class="date">
                    18
                    <sup>Dec</sup>
                    <sub>2020</sub>
                </div>
                <ul class="meta">
                    <li>
                        <span>Where</span>
                        Worldwide
                    </li>
                    <li>
                        <span>When</span>
                        Sunday (10.30 - 11.30)
                    </li>
                </ul>
                <ul class="info">
                    <li><i class="flaticon-coin"></i>100k</li>
                    <li><i class="flaticon-game"></i>1v1</li>
                    <li><i class="flaticon-game-1"></i>Mobile</li>
                    <li><i class="flaticon-teamwork"></i>10 Groups</li>
                </ul>
                <a routerLink="/single-tournament" class="default-btn">More Info</a>
            </div>
        </div>
    </div>

    <div class="single-tournaments-box">
        <div class="tournaments-content">
            <div class="content">
                <h3><a routerLink="/single-tournament">Halo Championship Series</a></h3>
                <div class="date">
                    15
                    <sup>Nov</sup>
                    <sub>2020</sub>
                </div>
                <ul class="meta">
                    <li>
                        <span>Where</span>
                        Worldwide
                    </li>
                    <li>
                        <span>When</span>
                        Sunday (10.00 - 12.00)
                    </li>
                </ul>
                <ul class="info">
                    <li><i class="flaticon-coin"></i>200k</li>
                    <li><i class="flaticon-game"></i>2v2</li>
                    <li><i class="flaticon-game-computer"></i>PC</li>
                    <li><i class="flaticon-teamwork"></i>08 Groups</li>
                </ul>
                <a routerLink="/single-tournament" class="default-btn">More Info</a>
            </div>
        </div>

        <div class="tournaments-image">
            <div class="image bg2">
                <img src="assets/img/upcoming-tournaments-img2.jpg" alt="image">
            </div>
        </div>
    </div>

    <div class="single-tournaments-box">
        <div class="tournaments-image">
            <div class="image bg3">
                <img src="assets/img/upcoming-tournaments-img3.jpg" alt="image">
            </div>
        </div>

        <div class="tournaments-content">
            <div class="content">
                <h3><a routerLink="/single-tournament">Global Starcraft II League</a></h3>
                <div class="date">
                    20
                    <sup>Jan</sup>
                    <sub>2021</sub>
                </div>
                <ul class="meta">
                    <li>
                        <span>Where</span>
                        Worldwide
                    </li>
                    <li>
                        <span>When</span>
                        Sunday (12.30 - 11.30)
                    </li>
                </ul>
                <ul class="info">
                    <li><i class="flaticon-coin"></i>300k</li>
                    <li><i class="flaticon-game"></i>10v10</li>
                    <li><i class="flaticon-game-1"></i>Mobile</li>
                    <li><i class="flaticon-teamwork"></i>5 Groups</li>
                </ul>
                <a routerLink="/single-tournament" class="default-btn">More Info</a>
            </div>
        </div>
    </div>
</div>