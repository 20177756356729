<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="STREAM SCHEDULE">STREAM SCHEDULE</h1>
        </div>
    </div>
</section>

<section class="stream-schedule-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-stream-schedule-box">
                    <span class="date">Oct <br> 23</span>
                    <div class="content">
                        <span class="time">14:30 - 15:30 EST</span>
                        <h3>ARENA OF VALOR WORLD CUP</h3>
                    </div>
                    <a href="#" class="link-btn" target="_blank"></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-stream-schedule-box not-streaming">
                    <span class="date">Jun <br> 25</span>
                    <div class="content">
                        <span class="time">Not Streaming</span>
                        <h3>CALL OF DUTY LEAGUE</h3>
                    </div>
                    <a href="#" class="link-btn" target="_blank"></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-stream-schedule-box">
                    <span class="date">Jan <br> 25</span>
                    <div class="content">
                        <span class="time">11:30 - 12:30 EST</span>
                        <h3>ESL USA PREMIERSHIP</h3>
                    </div>
                    <a href="#" class="link-btn" target="_blank"></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-stream-schedule-box">
                    <span class="date">20 <br> June</span>
                    <div class="content">
                        <span class="time">14:30 - 15:30 EST</span>
                        <h3>EUROPEAN GAMING LEAGUE</h3>
                    </div>
                    <a href="#" class="link-btn" target="_blank"></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-stream-schedule-box not-streaming">
                    <span class="date">Dec <br> 18</span>
                    <div class="content">
                        <span class="time">Not Streaming</span>
                        <h3>APEX LEGENDS GLOBAL SERIES</h3>
                    </div>
                    <a href="#" class="link-btn" target="_blank"></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-stream-schedule-box">
                    <span class="date">Jan <br> 25</span>
                    <div class="content">
                        <span class="time">11:30 - 12:30 EST</span>
                        <h3>HALO CHAMPIONSHIP SERIES</h3>
                    </div>
                    <a href="#" class="link-btn" target="_blank"></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-stream-schedule-box">
                    <span class="date">20 <br> June</span>
                    <div class="content">
                        <span class="time">14:30 - 15:30 EST</span>
                        <h3>GLOBAL STARCRAFT II LEAGUE</h3>
                    </div>
                    <a href="#" class="link-btn" target="_blank"></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-stream-schedule-box not-streaming">
                    <span class="date">Dec <br> 18</span>
                    <div class="content">
                        <span class="time">Not Streaming</span>
                        <h3>APEX LEGENDS GLOBAL SERIES</h3>
                    </div>
                    <a href="#" class="link-btn" target="_blank"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>