<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <img src="assets/img/logo.png" alt="image">
                <h2>A new exciting 1 versus 1 tabletop game</h2>
                <h6>We Are Launching Soon</h6>
                <p>Subscribe to receive the more juicy news about our game.</p>
                <div id="timer" class="flex-wrap d-flex justify-content-center">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}}
                        <span>Days</span>
                    </div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}}
                        <span>Hours</span>
                    </div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}}
                        <span>Minutes</span>
                    </div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}}
                        <span>Seconds</span>
                    </div>
                </div>
                <form *ngIf="!emailId" [formGroup]="formData" (ngSubmit)="submit()"
                    class="newsletter-form" action="https://zartugames.us1.list-manage.com/subscribe/post" method="post"
                    id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" novalidate>
                    <input type="hidden" name="u" value="b2a62f3dd57319d71b682033f" />
                    <input type="hidden" name="id" value="1604f29fcf" />
                    <div class="form-group">
                        <input type="email" class="input-newsletter" formControlName="emailId"
                            placeholder="Enter your email" id="mce-EMAIL" name="EMAIL" required autocomplete="on">
                    </div>
                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
                            name="b_b2a62f3dd57319d71b682033f_1604f29fcf" tabindex="-1" value=""></div>
                    <button type="submit" class="optional-btn"><i
                            class="flaticon-user"></i>Subscribe<span></span></button>
                </form>
                <br/>
                <h6>Are you interested to find live information about the game?</h6>
                <a class="default-btn" href="https://discord.gg/UcWswtdeFk"><i
                        class="flaticon-user"></i>Join our Discord<span></span></a>
                <p *ngIf="emailId" class="newsletter-success">Thank you for subscribing! ({{ emailId }})</p>
            </div>
        </div>
    </div>
</div>
