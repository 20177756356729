<div class="container">
    <div class="section-title">
        <span class="sub-title">TOURNAMENTS</span>
        <h2>Popular Leagues</h2>
    </div>

    <div class="single-popular-leagues-box">
        <div class="popular-leagues-box">
            <div class="popular-leagues-image">
                <div class="image bg1">
                    <img src="assets/img/popular-leagues-img1.jpg" alt="image">
                </div>
            </div>

            <div class="popular-leagues-content">
                <div class="content">
                    <h3><a routerLink="/single-tournament">Arena of Valor World Cup</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="info">
                        <li><i class="flaticon-coin"></i>100k</li>
                        <li><i class="flaticon-game"></i>1v1</li>
                        <li><i class="flaticon-game-1"></i>Mobile</li>
                        <li><i class="flaticon-teamwork"></i>10 Groups</li>
                    </ul>
                    <a routerLink="/single-tournament" class="join-now-btn">Join Now</a>
                </div>
            </div>

            <div class="popular-leagues-date">
                <div class="date">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <span>2020</span>
                            <h3>23 October</h3>
                            <p>14:30 PM</p>
                            <i class='bx bx-calendar'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-popular-leagues-box">
        <div class="popular-leagues-box">
            <div class="popular-leagues-image">
                <div class="image bg2">
                    <img src="assets/img/popular-leagues-img2.jpg" alt="image">
                </div>
            </div>

            <div class="popular-leagues-content">
                <div class="content">
                    <h3><a routerLink="/single-tournament">Call of Duty League</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="info">
                        <li><i class="flaticon-coin"></i>200k</li>
                        <li><i class="flaticon-game"></i>2v2</li>
                        <li><i class="flaticon-game-computer"></i>PC</li>
                        <li><i class="flaticon-teamwork"></i>08 Groups</li>
                    </ul>
                    <a routerLink="/single-tournament" class="join-now-btn">Join Now</a>
                </div>
            </div>

            <div class="popular-leagues-date">
                <div class="date">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <span>2020</span>
                            <h3>25 June</h3>
                            <p>10:00 PM</p>
                            <i class='bx bx-calendar'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-popular-leagues-box">
        <div class="popular-leagues-box">
            <div class="popular-leagues-image">
                <div class="image bg3">
                    <img src="assets/img/popular-leagues-img3.jpg" alt="image">
                </div>
            </div>

            <div class="popular-leagues-content">
                <div class="content">
                    <h3><a routerLink="/single-tournament">ESL USA Premiership</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="info">
                        <li><i class="flaticon-coin"></i>300k</li>
                        <li><i class="flaticon-game"></i>10v10</li>
                        <li><i class="flaticon-game-1"></i>Mobile</li>
                        <li><i class="flaticon-teamwork"></i>5 Groups</li>
                    </ul>
                    <a routerLink="/single-tournament" class="join-now-btn">Join Now</a>
                </div>
            </div>

            <div class="popular-leagues-date">
                <div class="date">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <span>2020</span>
                            <h3>25 January</h3>
                            <p>14:00 PM</p>
                            <i class='bx bx-calendar'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-popular-leagues-box">
        <div class="popular-leagues-box">
            <div class="popular-leagues-image">
                <div class="image bg4">
                    <img src="assets/img/popular-leagues-img4.jpg" alt="image">
                </div>
            </div>

            <div class="popular-leagues-content">
                <div class="content">
                    <h3><a routerLink="/single-tournament">European Gaming League</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="info">
                        <li><i class="flaticon-coin"></i>500k</li>
                        <li><i class="flaticon-game"></i>5v5</li>
                        <li><i class="flaticon-game-computer"></i>PC</li>
                        <li><i class="flaticon-teamwork"></i>11 Groups</li>
                    </ul>
                    <a routerLink="/single-tournament" class="join-now-btn">Join Now</a>
                </div>
            </div>

            <div class="popular-leagues-date">
                <div class="date">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <span>2020</span>
                            <h3>20 June</h3>
                            <p>15:00 PM</p>
                            <i class='bx bx-calendar'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>