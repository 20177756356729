<section class="live-stream-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Watch</span>
            <h2>Live Stream</h2>
        </div>
        <div class="live-stream-tabs">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="twitch-tab" data-bs-toggle="tab" data-bs-target="#twitch" type="button" role="tab" aria-controls="twitch" aria-selected="true">Twitch</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="youtube-tab" data-bs-toggle="tab" data-bs-target="#youtube" type="button" role="tab" aria-controls="youtube" aria-selected="false">Youtube</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="mixer-tab" data-bs-toggle="tab" data-bs-target="#mixer" type="button" role="tab" aria-controls="mixer" aria-selected="false">Mixer</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="vimeo-tab" data-bs-toggle="tab" data-bs-target="#vimeo" type="button" role="tab" aria-controls="vimeo" aria-selected="false">Vimeo</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="twitch" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-7 col-md-7">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img1.jpg" alt="image">
                                <div class="content">
                                    <h3>Fortnite 2 Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img2.jpg" alt="image">
                                <div class="content">
                                    <h3>Pubg Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img3.jpg" alt="image">
                                <div class="content">
                                    <h3>Free Fire Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img4.jpg" alt="image">
                                <div class="content">
                                    <h3>Combat 5 Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img5.jpg" alt="image">
                                <div class="content">
                                    <h3>Control Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="youtube" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img3.jpg" alt="image">
                                <div class="content">
                                    <h3>Free Fire Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img4.jpg" alt="image">
                                <div class="content">
                                    <h3>Combat 5 Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img5.jpg" alt="image">
                                <div class="content">
                                    <h3>Control Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img1.jpg" alt="image">
                                <div class="content">
                                    <h3>Fortnite 2 Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img2.jpg" alt="image">
                                <div class="content">
                                    <h3>Pubg Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="mixer" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-7 col-md-7">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img1.jpg" alt="image">
                                <div class="content">
                                    <h3>Fortnite 2 Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img2.jpg" alt="image">
                                <div class="content">
                                    <h3>Pubg Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img3.jpg" alt="image">
                                <div class="content">
                                    <h3>Free Fire Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img4.jpg" alt="image">
                                <div class="content">
                                    <h3>Combat 5 Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img5.jpg" alt="image">
                                <div class="content">
                                    <h3>Control Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="vimeo" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img3.jpg" alt="image">
                                <div class="content">
                                    <h3>Free Fire Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img4.jpg" alt="image">
                                <div class="content">
                                    <h3>Combat 5 Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img5.jpg" alt="image">
                                <div class="content">
                                    <h3>Control Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>

                        <div class="col-lg-7 col-md-7">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img1.jpg" alt="image">
                                <div class="content">
                                    <h3>Fortnite 2 Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-5">
                            <div class="single-live-stream-box">
                                <img src="assets/img/live-stream-img2.jpg" alt="image">
                                <div class="content">
                                    <h3>Pubg Live <i class="flaticon-play"></i></h3>
                                </div>
                                <a href="#" target="_blank" class="link-btn" target="_blank"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>