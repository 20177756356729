<div class="navbar-area navbar-style-wop">
    <div class="zinble-responsive-nav">
        <div class="container">
            <div class="zinble-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="zinble-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link">{{ 'navbar.menu.game' | translate }}</a></li>

                        <li class="nav-item"><a routerLink="/champions" class="nav-link">{{ 'navbar.menu.champions' | translate }}</a></li>

                        <li class="nav-item"><a routerLink="/potm" class="nav-link">{{ 'navbar.menu.potm' | translate }}</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">{{ 'navbar.menu.universe' | translate }} <i class="flaticon-down-arrow"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/lore" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'navbar.menu.lore' | translate }}</a></li>
<!-- //Fan art when it will exist
                                <li class="nav-item"><a routerLink="/zartarians" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'navbar.menu.zartarians' | translate }}</a></li> -->

                                <li class="nav-item"><a href="https://worldofpirates.ideascale.com/" class="nav-link">{{ 'navbar.menu.community' | translate }}</a></li>

                                <li class="nav-item"><a href="https://zartugames.com" class="nav-link">{{ 'navbar.menu.zartugames' | translate }}</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">{{ 'navbar.menu.news' | translate }} <i class="flaticon-down-arrow"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/gameupdates" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'navbar.menu.gameupdates' | translate }}</a></li>

                                <li class="nav-item"><a routerLink="/dev" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'navbar.menu.dev' | translate }}</a></li>

                                <li class="nav-item"><a routerLink="/roadmap" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'navbar.menu.roadmap' | translate }}</a></li>
                            </ul>
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <div class="account-btn">
                                <a routerLink="/account">
                                    <i class='bx bxs-user'></i>                              
                                </a>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="flaticon-null-2" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <div class="account-btn">
                                <a routerLink="/account">
                                    <i class='bx bxs-user'></i>  
                                </a>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="flaticon-null-2" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="sidebarModal modal right fade" id="sidebarModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal"><i class='bx bx-x'></i></button>

            <div class="modal-body">
                <div class="logo">
                    <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
                </div>
                
                <div class="sidebar-contact-info">
                    <h2>
                        <a href="mailto:worldofpirates@zartugames.com">SUPPORT</a>
                    </h2>
                </div>

                <ul class="social-list">
                    <li><span>Follow Us On:</span></li>
                    <li><a href="https://www.facebook.com/Worldofpiratesio" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://twitter.com/worldofpirates_" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UCi4evHE0gtUSQdmxEKsqkbQ" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    <li><a href="https://www.twitch.tv/zartugames" target="_blank"><i class='bx bxl-twitch'></i></a></li>
                    <li><a href="https://discord.gg/DQ6emp7S" target="_blank"><i class='bx bxl-discord'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>