import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popular-leagues',
  templateUrl: './popular-leagues.component.html',
  styleUrls: ['./popular-leagues.component.scss']
})
export class PopularLeaguesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
